import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {EventMessage, EventMessageType} from '../../../domain/event-message';
import {DateTimeUtilService} from '../../../services/date-time-util/date-time-util.service';
import {MessageStatus} from '../../../domain/message-status';
import {DecimalAmountPipe} from '../../../pipes/decimal-input/decimal-input.pipe';

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements OnInit, OnChanges {

    readonly MessageStatus = MessageStatus;
    readonly EventMessageType = EventMessageType;
    readonly DecimalAmountPipe = DecimalAmountPipe;
    @Input() event: EventMessage<object>;
    elapsedTimeLabel: string;

    constructor(private readonly dateTimeUtilService: DateTimeUtilService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.computeElapsedTimeLabel();
    }

    private computeElapsedTimeLabel(): void {
        this.elapsedTimeLabel = this.dateTimeUtilService.getTimeSinceLabel(this.event.createdDate);
    }
}

