export const environment = {
    production: false,
    enableSSLPinning: false,
    idleTimeInSec: 190,
    appName: 'TreasurUp Mobile FX Demo',
    platform: "ios",
    apiVersion: '2.12.0',
    defaultAppLanguage: 'en',
    iamClientID: 'trup-mobile',
    logLevel: 0,
    serverLogLevel: 2,
    androidStoreLink: 'https://play.google.com/apps/internaltest/4698485669449249527',
    iosStoreLink: 'https://apps.apple.com/ro/app/rabo-easy-fx/id1466868830',
    iosBundleId: 'com.treasurup.demo',
    androidBundleId: 'com.treasurup.demo',
    oneSignal: {
        apiKeyIos: '',
        apiKeyAndroid: ''
    },
    firebase: {
        apiKey: 'AIzaSyA-kbR_SQfHiC-NfJ9G8Y7UZHtQ6uv9_KQ',
        authDomain: 'treasurup-demo.firebaseio.com',
        databaseURL: 'https://treasurup-demo.firebaseio.com',
        projectId: 'treasurup-demo',
        storageBucket: 'gs://treasurup-demo.appspot.com',
        messagingSenderId: '211979063605'
    },
    base: {
        apiBasePath: 'https://demo.treasurup.treasurup.tech',
        webSocketPath: 'wss://demo.treasurup.treasurup.tech/websocket',
        iamIssuerURL: 'https://demo.treasurup.treasurup.tech/auth/realms/trup1',
        appLink: 'com.treasurup.demo',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, DEVICE_REGISTRATION, QR_LOGIN'
    },
    alt: {
        apiBasePath: 'https://demo.treasurup.treasurup.tech',
        webSocketPath: 'wss://demo.treasurup.treasurup.tech/websocket',
        iamIssuerURL: 'https://demo.treasurup.treasurup.tech/auth/realms/trup1',
        appLink: 'com.treasurup.demo',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, DEVICE_REGISTRATION, QR_LOGIN'
    }
};
