import {Injectable} from "@angular/core";
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';

@Injectable({providedIn: 'root'})
export class SocialShareService {

    constructor(private readonly socialSharing: SocialSharing) {}

    shareScreenshot(filePath: string, fileName: string): void {
        const path = `${filePath}${fileName}`;
        this.socialSharing.share(undefined, undefined, path);
    }
}