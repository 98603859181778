import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtils} from '../../commons/http/http-utils';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class InvalidBusinessDayService {

    constructor(public http: HttpClient) {
    }

    getInvalidBusinessDays(currencies?: string[]): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/invalid-business-days?currencies=${currencies}`;
        return this.http.get<string[]>(url, HttpUtils.getRequestOptions());
    }

    getValidSpotDays(currencies: string[]): Observable<string[]> {
        const apiBasePath = FirebaseService.getApiBasePath();
        const url = `${apiBasePath}/api/global/invalid-business-days/valid-spot-days?currencies=${currencies}`;
        return this.http.get<string[]>(url, HttpUtils.getRequestOptions());
    }
}
