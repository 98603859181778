<div [class]="event.messageStatus === MessageStatus.UNREAD ? 'bullet' : 'bullet-free'">
    <div class="container-wrapper">
        <label>{{elapsedTimeLabel}}</label>
        <div class="body"
             [ngSwitch]="event.eventMessageType">
            <ng-container *ngSwitchCase="EventMessageType.RATE_NOTIFICATION">
                {{'notifications-page.notification.rate-notification.' + event.data.notificationDirection | translate:
                {
                    tradingCurrency: event.data.currencyPair.tradingCurrency,
                    contraCurrency: event.data.currencyPair.contraCurrency,
                    rateLimitThreshold: event.data.rateLimitThreshold | decimalAmount: DecimalAmountPipe.AMOUNT_FORMAT_4_DIGITS
                } }}
            </ng-container>
            <ng-container *ngSwitchCase="EventMessageType.UPCOMING_SETTLEMENT">
                <span>{{'notifications-page.notification.upcoming-settlement' | translate:
                    {
                        currency: event.data[0].tradingCurrency,
                        amount: event.data[0].hedgeAmount | decimalAmount,
                        side: event.data[0].side | lowercase,
                        settlementDate: event.data[0].hedgeDate | dateFormat
                    } }}
                </span>
                <span *ngIf="event.data.length > 1">{{'notifications-page.notification.more-items' | translate:
                    {
                        count: event.data.length - 1
                    } }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="EventMessageType.UPCOMING_TRADE_PLAN">
                <span>{{'notifications-page.notification.upcoming-trade-plan' | translate:
                    {
                        eventName: event.data.eventName,
                        projectName: event.data.projectName,
                        companyShortName: event.data.companyShortName
                    } }}
                </span>
                <span *ngIf="event.data.length > 1">{{'notifications-page.notification.more-items' | translate:
                    {
                        count: event.data.length - 1
                    } }}
                </span>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </div>
    </div>
</div>
