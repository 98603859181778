import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, noop, Observable} from 'rxjs';
import {MessageStatus} from '../../domain/message-status';
import {EventMessageService} from '../event-message/event-message.service';
import {WebSocketService} from '../web-socket/web-socket.service';
import {EventMessage} from '../../domain/event-message';
import {OnPlatformEvents} from '../../utils/on-platform-events';
import {Platform} from '@ionic/angular';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NotificationCountService extends OnPlatformEvents {
    unreadNotificationCountSubject = new BehaviorSubject<number>(0);
    private unreadNotificationCount = 0;

    private readonly subscribeAndHandleSubscription = notificationObservable =>
        notificationObservable.subscribe(eventMessage => this.handleEventMessage(eventMessage));

    constructor(readonly platform: Platform,
                readonly ngZone: NgZone,
                private readonly eventMessageService: EventMessageService,
                private readonly webSocketService: WebSocketService) {
        super(platform, ngZone);
        this.initializeNotificationCounter(true);
        this.webSocketService.getActivatedSubject().subscribe( () => this.initializeNotificationCounter());
    }

    ionPause(): void {
    }

    ionResume(): void {
    }

    private initializeNotificationCounter(subscribeToNotifications = false): void {
        this.getUnreadMessages().pipe(
            tap(count => {
                    this.unreadNotificationCount = count;
                    this.unreadNotificationCountSubject.next(this.unreadNotificationCount);
                }
            ),
            tap(() => subscribeToNotifications ? this.watchNotifications() : noop())
        ).subscribe();
    }

    private getUnreadMessages(): Observable<number> {
        return this.eventMessageService.count([MessageStatus.UNREAD]);
    }

    private watchNotifications(): void {
        this.subscribeAndHandleSubscription(this.webSocketService.watchUpcomingSettlementNotifications());
        this.subscribeAndHandleSubscription(this.webSocketService.watchAllRateNotifications());
        this.subscribeAndHandleSubscription(this.webSocketService.watchUpcomingTradePlanNotifications());
    }

    private handleEventMessage(eventMessage: EventMessage<any>): void {
        switch (eventMessage.messageStatus) {
            case MessageStatus.READ:
                this.unreadNotificationCount--;
                break;
            case MessageStatus.UNREAD:
                this.unreadNotificationCount++;
                break;
            case MessageStatus.DELETED:
                this.processDeletedMessage(eventMessage);
                break;
        }
        this.unreadNotificationCountSubject.next(this.unreadNotificationCount);
    }

    private processDeletedMessage(eventMessage: EventMessage<any>): void {
        const deleteUnreadMessage = eventMessage.statusHistory
            .map(eventMessageStatus => eventMessageStatus.messageStatus)
            .every(messageStatus => messageStatus !== MessageStatus.READ);
        deleteUnreadMessage ? this.unreadNotificationCount-- : noop();
    }
}
