import {MessageStatus} from './message-status';
import {AuditData} from './audit-data';

export class EventMessage<T> extends AuditData {
    id: string;
    eventId: string;
    eventMessageType: EventMessageType;
    data: T;
    messageStatus: MessageStatus;
    statusHistory: EventMessageStatus[];
}

export enum EventMessageType {
    RATE_LIMIT = 'RATE_LIMIT',
    RATE_NOTIFICATION = 'RATE_NOTIFICATION',
    QUOTE = 'QUOTE',
    USER_ACTIVATION = 'USER_ACTIVATION',
    PASSWORD_RESET = 'PASSWORD_RESET',
    HEDGE = 'HEDGE',
    ENRICH = 'ENRICH',
    CONFIGURE_2FA = 'CONFIGURE_2FA',
    UPCOMING_SETTLEMENT = 'UPCOMING_SETTLEMENT',
    UPCOMING_TRADE_PLAN = 'UPCOMING_TRADE_PLAN'
}

export class EventMessageStatus {
    messageStatus: MessageStatus;
}
