 <ion-row [class.background-grey]="backgroundGrey">
        <ion-col size="3.5">
            <div class="subtitle" [class.subtitle-margin-top]="shownDates.showSettlementDates && shownDates.showTradeDates">
                {{'insights.labels.net-positions' | translate}}
            </div>
        </ion-col>
        <ion-col size="8.5" class="text-small">
            <ion-row *ngIf="!shownDates.showSettlementDates && !shownDates.showTradeDates">
                <ion-col size="12" class="ion-text-end">
                    <span class="label">{{'insights.labels.settlement' | translate}}</span>
                    <span>{{shownDates.settlementDateFrom | dateFormat}}</span>
                    <span class="dash">-</span>
                    <span>{{shownDates.settlementDateTo | dateFormat}}</span>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="shownDates.showTradeDates">
                <ion-col size="12" class="ion-text-end">
                    <span class="label">{{'insights.labels.traded' | translate}}</span>
                    <span>{{shownDates.tradeDateFrom | dateFormat}}</span>
                    <span class="dash">-</span>
                    <span>{{shownDates.tradeDateTo | dateFormat}}</span>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="shownDates.showSettlementDates">
                <ion-col size="12" class="ion-text-end">
                    <span class="label">{{'insights.labels.settlement' | translate}}</span>
                    <span>{{shownDates.settlementDateFrom | dateFormat}}</span>
                    <span class="dash">-</span>
                    <span>{{shownDates.settlementDateTo | dateFormat}}</span>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>
