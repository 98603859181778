import {Component, DestroyRef, Input, OnInit, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {of, from} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import html2canvas from 'html2canvas';
import {SocialShareService} from 'src/app/services/social-share/social-share.service';
import {FileService} from 'src/app/services/file/file.service';
import {Platform} from '@ionic/angular';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

    public static readonly REPORT_ID = 'report';

    private readonly REPORT_NAME = 'report.png';
    private readonly destroy: DestroyRef = inject(DestroyRef);
    
    isGeneratingReport = false;
    isAndroid = false;

    @Input() reportElement: HTMLElement;

    constructor(private readonly socialShareService: SocialShareService,
                private readonly fileService: FileService, 
                private readonly platform: Platform) {
    }

    ngOnInit(): void {
        this.isAndroid = this.platform.is('android');
    }

    share(): void {
        this.isGeneratingReport = true;
        of(this.reportElement)
            .pipe(
                takeUntilDestroyed(this.destroy),
                delay(100),
                switchMap(node => from(
                    html2canvas(node, {
                        scrollY: -window.scrollY
                    })
                )),
                switchMap(canvas => from(new Promise<Blob>((resolve) => canvas.toBlob(resolve)))),
                switchMap(blob => this.fileService.saveFile(this.REPORT_NAME, blob)),
            )
            .subscribe(() => {
                this.socialShareService.shareScreenshot(this.fileService.dataDirectoryPath, this.REPORT_NAME);
                this.isGeneratingReport = false;
            });
    }
}
